
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexZTX_IZPL2skf78HAusPhyfApmGSJtKkEgWCm_45XhWoekMeta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/index.vue?macro=true";
import { default as indexXzsm5OiM51MkrSjDTtNQM1KO70541Zsel_7qIRaujsAMeta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startGLiUBVC1tG_Bpe9Ca93iO8T17YQTUwh3bJ7YWeQAO6gMeta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as start3T_Hp12imsj8rMdtyCYaw0NjDyAUZv3Os2BLcE4t24YMeta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutYFZgnMPYkhb2_731iAyxx_45AbJuokmJFlms5H2071IUcMeta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as kot4qQaNT3oUJx_45uNBx6luGsb2hRugvxhhWBhrylGPrpYMeta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationy_jbsI2iiY874U3b_45N5XygJhXyTpz3lnmhT7CkKk_45MUMeta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationNO4lJUjEYYfGLZWwh3vLQeVCVuFkugnLuEXxlt2rnhQMeta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyerGBwfEBYPAmbsJaKTR_Q9nf1wLZJfWeCE5oStO1BgyBwMeta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerHaBK14HJs2VYKhTjLPl9ZGqNZuP1zRRbbvQ8_45KjuMtgMeta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as payment_I14z6pJDxuziyOZNwTykr2bJzM0q5I5nX237_45d970UMeta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challenge_45WjsHLc8QEs8wZUoeN6ybJLF_45Xv7npxI32Lth3jGq6kMeta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentkDvUvwqcM5kszDajw1zf72vxXLB3J5yocXqvQDg5hDoMeta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta } from "/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexZTX_IZPL2skf78HAusPhyfApmGSJtKkEgWCm_45XhWoekMeta?.name ?? "index",
    path: indexZTX_IZPL2skf78HAusPhyfApmGSJtKkEgWCm_45XhWoekMeta?.path ?? "/",
    props: indexZTX_IZPL2skf78HAusPhyfApmGSJtKkEgWCm_45XhWoekMeta?.props ?? false,
    meta: indexZTX_IZPL2skf78HAusPhyfApmGSJtKkEgWCm_45XhWoekMeta || {},
    alias: indexZTX_IZPL2skf78HAusPhyfApmGSJtKkEgWCm_45XhWoekMeta?.alias || [],
    redirect: indexZTX_IZPL2skf78HAusPhyfApmGSJtKkEgWCm_45XhWoekMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/index.vue")
  },
  {
    name: indexXzsm5OiM51MkrSjDTtNQM1KO70541Zsel_7qIRaujsAMeta?.name ?? "lang",
    path: indexXzsm5OiM51MkrSjDTtNQM1KO70541Zsel_7qIRaujsAMeta?.path ?? "/:lang?",
    props: indexXzsm5OiM51MkrSjDTtNQM1KO70541Zsel_7qIRaujsAMeta?.props ?? false,
    meta: indexXzsm5OiM51MkrSjDTtNQM1KO70541Zsel_7qIRaujsAMeta || {},
    alias: indexXzsm5OiM51MkrSjDTtNQM1KO70541Zsel_7qIRaujsAMeta?.alias || [],
    redirect: indexXzsm5OiM51MkrSjDTtNQM1KO70541Zsel_7qIRaujsAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startGLiUBVC1tG_Bpe9Ca93iO8T17YQTUwh3bJ7YWeQAO6gMeta?.name ?? "lang-voucher-start",
    path: startGLiUBVC1tG_Bpe9Ca93iO8T17YQTUwh3bJ7YWeQAO6gMeta?.path ?? "/:lang?/voucher/start",
    props: startGLiUBVC1tG_Bpe9Ca93iO8T17YQTUwh3bJ7YWeQAO6gMeta?.props ?? false,
    meta: startGLiUBVC1tG_Bpe9Ca93iO8T17YQTUwh3bJ7YWeQAO6gMeta || {},
    alias: startGLiUBVC1tG_Bpe9Ca93iO8T17YQTUwh3bJ7YWeQAO6gMeta?.alias || [],
    redirect: startGLiUBVC1tG_Bpe9Ca93iO8T17YQTUwh3bJ7YWeQAO6gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: start3T_Hp12imsj8rMdtyCYaw0NjDyAUZv3Os2BLcE4t24YMeta?.name ?? "lang-purchase-start",
    path: start3T_Hp12imsj8rMdtyCYaw0NjDyAUZv3Os2BLcE4t24YMeta?.path ?? "/:lang?/purchase/start",
    props: start3T_Hp12imsj8rMdtyCYaw0NjDyAUZv3Os2BLcE4t24YMeta?.props ?? false,
    meta: start3T_Hp12imsj8rMdtyCYaw0NjDyAUZv3Os2BLcE4t24YMeta || {},
    alias: start3T_Hp12imsj8rMdtyCYaw0NjDyAUZv3Os2BLcE4t24YMeta?.alias || [],
    redirect: start3T_Hp12imsj8rMdtyCYaw0NjDyAUZv3Os2BLcE4t24YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutYFZgnMPYkhb2_731iAyxx_45AbJuokmJFlms5H2071IUcMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutYFZgnMPYkhb2_731iAyxx_45AbJuokmJFlms5H2071IUcMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutYFZgnMPYkhb2_731iAyxx_45AbJuokmJFlms5H2071IUcMeta?.props ?? false,
    meta: checkoutYFZgnMPYkhb2_731iAyxx_45AbJuokmJFlms5H2071IUcMeta || {},
    alias: checkoutYFZgnMPYkhb2_731iAyxx_45AbJuokmJFlms5H2071IUcMeta?.alias || [],
    redirect: checkoutYFZgnMPYkhb2_731iAyxx_45AbJuokmJFlms5H2071IUcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: kot4qQaNT3oUJx_45uNBx6luGsb2hRugvxhhWBhrylGPrpYMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: kot4qQaNT3oUJx_45uNBx6luGsb2hRugvxhhWBhrylGPrpYMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: kot4qQaNT3oUJx_45uNBx6luGsb2hRugvxhhWBhrylGPrpYMeta?.props ?? false,
    meta: kot4qQaNT3oUJx_45uNBx6luGsb2hRugvxhhWBhrylGPrpYMeta || {},
    alias: kot4qQaNT3oUJx_45uNBx6luGsb2hRugvxhhWBhrylGPrpYMeta?.alias || [],
    redirect: kot4qQaNT3oUJx_45uNBx6luGsb2hRugvxhhWBhrylGPrpYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationy_jbsI2iiY874U3b_45N5XygJhXyTpz3lnmhT7CkKk_45MUMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationy_jbsI2iiY874U3b_45N5XygJhXyTpz3lnmhT7CkKk_45MUMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationy_jbsI2iiY874U3b_45N5XygJhXyTpz3lnmhT7CkKk_45MUMeta?.props ?? false,
    meta: confirmationy_jbsI2iiY874U3b_45N5XygJhXyTpz3lnmhT7CkKk_45MUMeta || {},
    alias: confirmationy_jbsI2iiY874U3b_45N5XygJhXyTpz3lnmhT7CkKk_45MUMeta?.alias || [],
    redirect: confirmationy_jbsI2iiY874U3b_45N5XygJhXyTpz3lnmhT7CkKk_45MUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationNO4lJUjEYYfGLZWwh3vLQeVCVuFkugnLuEXxlt2rnhQMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationNO4lJUjEYYfGLZWwh3vLQeVCVuFkugnLuEXxlt2rnhQMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationNO4lJUjEYYfGLZWwh3vLQeVCVuFkugnLuEXxlt2rnhQMeta?.props ?? false,
    meta: confirmationNO4lJUjEYYfGLZWwh3vLQeVCVuFkugnLuEXxlt2rnhQMeta || {},
    alias: confirmationNO4lJUjEYYfGLZWwh3vLQeVCVuFkugnLuEXxlt2rnhQMeta?.alias || [],
    redirect: confirmationNO4lJUjEYYfGLZWwh3vLQeVCVuFkugnLuEXxlt2rnhQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyerGBwfEBYPAmbsJaKTR_Q9nf1wLZJfWeCE5oStO1BgyBwMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerGBwfEBYPAmbsJaKTR_Q9nf1wLZJfWeCE5oStO1BgyBwMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyerGBwfEBYPAmbsJaKTR_Q9nf1wLZJfWeCE5oStO1BgyBwMeta?.props ?? false,
    meta: buyerGBwfEBYPAmbsJaKTR_Q9nf1wLZJfWeCE5oStO1BgyBwMeta || {},
    alias: buyerGBwfEBYPAmbsJaKTR_Q9nf1wLZJfWeCE5oStO1BgyBwMeta?.alias || [],
    redirect: buyerGBwfEBYPAmbsJaKTR_Q9nf1wLZJfWeCE5oStO1BgyBwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerHaBK14HJs2VYKhTjLPl9ZGqNZuP1zRRbbvQ8_45KjuMtgMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerHaBK14HJs2VYKhTjLPl9ZGqNZuP1zRRbbvQ8_45KjuMtgMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerHaBK14HJs2VYKhTjLPl9ZGqNZuP1zRRbbvQ8_45KjuMtgMeta?.props ?? false,
    meta: buyerHaBK14HJs2VYKhTjLPl9ZGqNZuP1zRRbbvQ8_45KjuMtgMeta || {},
    alias: buyerHaBK14HJs2VYKhTjLPl9ZGqNZuP1zRRbbvQ8_45KjuMtgMeta?.alias || [],
    redirect: buyerHaBK14HJs2VYKhTjLPl9ZGqNZuP1zRRbbvQ8_45KjuMtgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: payment_I14z6pJDxuziyOZNwTykr2bJzM0q5I5nX237_45d970UMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: payment_I14z6pJDxuziyOZNwTykr2bJzM0q5I5nX237_45d970UMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: payment_I14z6pJDxuziyOZNwTykr2bJzM0q5I5nX237_45d970UMeta?.props ?? false,
    meta: payment_I14z6pJDxuziyOZNwTykr2bJzM0q5I5nX237_45d970UMeta || {},
    alias: payment_I14z6pJDxuziyOZNwTykr2bJzM0q5I5nX237_45d970UMeta?.alias || [],
    redirect: payment_I14z6pJDxuziyOZNwTykr2bJzM0q5I5nX237_45d970UMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challenge_45WjsHLc8QEs8wZUoeN6ybJLF_45Xv7npxI32Lth3jGq6kMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challenge_45WjsHLc8QEs8wZUoeN6ybJLF_45Xv7npxI32Lth3jGq6kMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challenge_45WjsHLc8QEs8wZUoeN6ybJLF_45Xv7npxI32Lth3jGq6kMeta?.props ?? false,
    meta: challenge_45WjsHLc8QEs8wZUoeN6ybJLF_45Xv7npxI32Lth3jGq6kMeta || {},
    alias: challenge_45WjsHLc8QEs8wZUoeN6ybJLF_45Xv7npxI32Lth3jGq6kMeta?.alias || [],
    redirect: challenge_45WjsHLc8QEs8wZUoeN6ybJLF_45Xv7npxI32Lth3jGq6kMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentkDvUvwqcM5kszDajw1zf72vxXLB3J5yocXqvQDg5hDoMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentkDvUvwqcM5kszDajw1zf72vxXLB3J5yocXqvQDg5hDoMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentkDvUvwqcM5kszDajw1zf72vxXLB3J5yocXqvQDg5hDoMeta?.props ?? false,
    meta: paymentkDvUvwqcM5kszDajw1zf72vxXLB3J5yocXqvQDg5hDoMeta || {},
    alias: paymentkDvUvwqcM5kszDajw1zf72vxXLB3J5yocXqvQDg5hDoMeta?.alias || [],
    redirect: paymentkDvUvwqcM5kszDajw1zf72vxXLB3J5yocXqvQDg5hDoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/arenys-de-mar/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/barcelona/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/berga/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/caldes-de-montbui/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/calella/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/la-garriga/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/masquefa/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/mataro/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 10",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/sant-boi-de-llobregat/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 11",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/sant-esteve-sesrovires/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 12",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/sant-joan-despi/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 13",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/seva/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 14",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/sitges/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.name ?? "Spa by location landing: 15",
    path: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.path ?? "/spa-balneario/barcelona/vallromanes/",
    props: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.props ?? false,
    meta: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta || {},
    alias: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.alias || [],
    redirect: SpaList_46pagedKfjDtwzdW0Lo_bvbK2cWCxNw7jfV4HrjrTETlb3hLYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/spa-berga-resort/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 5",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 6",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 7",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 8",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 9",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 10",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 11",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 12",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 13",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/inner-flow/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 14",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/spa-wellness-urh-ciutat-de-mataro/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 15",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 16",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 17",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 18",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 19",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 20",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 21",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 22",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/spa-siargao/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 23",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 24",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 25",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/nyxpert-the-royal-fitness-spa/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.name ?? "Spa landing: 26",
    path: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/",
    props: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.props ?? false,
    meta: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta || {},
    alias: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.alias || [],
    redirect: SpaDetail_46pagew8iH4BSDkgFThEADDAIDE3Z5TlxgbAP4IlqC_TzHSh0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-desayuno-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-viernes-domingo/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-desayuno-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-cava-lunes-jueves/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-menu-sofia-bar-tapas-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-desayuno-lunes-jueves/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-cava-viernes-domingo/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-desayuno-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-desayuno-viernes-domingo/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-terapeutico-50-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-lunes-jueves/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-desayuno-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-menu-sofia-bar-tapas-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-deportivo-25-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-terapeutico-25-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-deportivo-50-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-menu-sofia-bar-tapas-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-menu-sofia-bar-tapas-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-deep-tisue-20-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/ritual-hydraluronic/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/viaje-a-la-provenza/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-relajante-50-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-relajante-20-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-menores-4-a-17anos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-60-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-placido-sueno/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/reina-de-egipto-circuito/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/circuito-termal-1h30min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-buffet-restaurante/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/antiestres-45/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/antiestres-25/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia-masaje-doble/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-para-dos-masaje-doble/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-para-dos-masaje-doble/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/pack-armonia/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia-masaje-consecutivo/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-buffet/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/masaje-karite-55/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-buffet-masaje-doble/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-buffet/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-buffet-masaje-doble/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/date-un-capricho-acceso-al-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/express-massage-acceso-al-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/lulur-ritual-acceso-al-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/choccolat-massage-scrub-acceso-al-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/ritual-candle-acceso-al-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/magic-honey-acceso-al-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/back-soul-acceso-al-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-berga-resort/escapada-parejas-estancia-masaje-spa-piscina-fitness/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-bodycare/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-skincare/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-care-para-parejas/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-eternal-love-para-parejas/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/spa-privado/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-descontacturante/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-cielo-y-tierra/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-lomi-lomi/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-de-la-sensualidad/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-de-la-alegria/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-antiestres/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/kids-massage/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas-2-ninos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas-nino/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/gourmet-love-para-parejas/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-gourmet-para-parejas/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-ayurvedico/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-ayurvedico-con-pindas/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/glow-flow-spa-ritual/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/deep-tissue-50-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/digital-wellness-escape/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/aromatherapy-back-massage/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/serenity-full-body-massage-80-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/rejuvenate/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/balmoral-signature-ritual/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/time-ritual-60-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/antistress-cabeza-cuello-y-hombros/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/despertar-ritual-de-relajacion-masaje-y-desayuno/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes-gold-edition/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/glow-recovery-facial-50-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/organic-signature-facial-by-scens-40-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/experiencia-del-mes-65-minutos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet-gold-edition/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet-silver-edition/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes-silver-edition/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-25min-parejas/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desconecta-para-conectar/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/cuidado-maternal-65min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-25min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-de-25-minutos-infantil/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/escapada-relax-foodie-spa-menu-almuerzo/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/experiencia-del-mes-40-minutos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-50min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/spa-familiar-mascarilla-facial-solidaria/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/silhoutte-perfection-massage-50-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/despertar-by-serena-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/hot-stone-bliss-90/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/deep-tissue-50-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/aromatherapy-back-massage-30-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/exfoliation-50min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/serenity-full-body-massage-80-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/couple-cocoon-by-serena-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/digital-wellness-escape/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/acceso-spa-circuito-aguas-50/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/firm-and-tone-sculpting-treatment/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/time-ritual-60-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/melia-exclusive-signature-ritual-by-serena-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/anti-stress-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/antiestres-cabeza-cuello-y-hombros/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/aromatherapy-back-massage/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/sir-victor-exclusive-signature-ritual-by-serena-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/eternal-youth/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-circuito-spa-30-y-masaje-50/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/exfoliation/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/deep-tissue-massage-50-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/slow-glow-masaje-y-circuito-spa-con-copa-de-cava-o-te/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/cityscape-spa-masaje-y-gastronomia-en-el-rooftop/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-circuito-spa-30-y-masaje-30/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-y-circuito-spa-50/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/vip-serena-spa-luxury-signature-ritual-y-spa-privado-para-2/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/white-light/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 137",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 138",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/vip-couple-cocoon-signature-ritual-y-spa-privado-para-2/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 139",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/beauty-party-spa-privado-90/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 140",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/hot-stone-bliss/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 141",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/entrada-spa-acceso-general-circuito-de-aguas-50/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 142",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/firm-and-tone-sculpting-treatment/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 143",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/digital-wellness/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 144",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/melia-exclusive-signature-ritual/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 145",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/anti-stress-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 146",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/time-ritual-60-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 147",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/aromatherapy-back-massage-30-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 148",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/deep-tissue-50-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 149",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/exfoliation/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 150",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/couple-cocoon-by-serena-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 151",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 152",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/mediterranean-flavours-90/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 153",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/signature-journey/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 154",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/sweet-orange-body-scrub/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 155",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/rejuvenate-60/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 156",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/aromaterapia-30/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 157",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/digital-wellness-escape-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 158",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/serenity-full-body-massage-50/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 159",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/despertar-by-serena-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 160",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/aromaterapia-50/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 161",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/time-ritual-60/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 162",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/deep-tissue-massage-50/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 163",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/experiencia-gastro-wellness-spa-50-con-opcion-masaje-y-cena-para-2-personas/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 164",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/acceso-spa-circuito-aguas-50/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 165",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-exclusive-spa-privado-para-2/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 166",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-luxury-sunrise-desayuno-buffet-circuito-termal-masaje-aromaterapia-40min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 167",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/sri-lanka-30min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 168",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-zen-circuito-termal-60min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 169",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-celebrate-spa-privado-grupos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 170",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-deluxe-circuito-termal-120min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 171",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/2x1-spazio-scape-circuito-termal-60min-lunes-a-viernes-11h-15h/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 172",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/peeling-con-hidratacion/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 173",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/aromaterapia-50min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 174",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/age-defense-50min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 175",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/mediterranean-bliss-120-spazio-para-1/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 176",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/ocean-miracle-50min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 177",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/sensations-50min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 178",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/higiene-activa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 179",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/cacao-tamakuru-90min-spazio-para-2/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 180",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/mediterranean-bliss-120-spazio-para-2/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 181",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/infinity-60min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 182",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/twin-bombay-80min-spazio-para-2/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 183",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/secretos-del-nilo-90min-spazio-para-1/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 184",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/deep-tissue-50min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 185",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/bambu-detox-80min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 186",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-lovers-circuito-termal-1h-masaje-cacao-tamakuru-40min-para-dos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 187",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-sunrise-desayuno-buffet-circuito-termal/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 188",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/holistico-del-tibet-90min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 189",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-wellness/futura-mama-50min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 190",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/inner-flow/dia-en-inner-flow/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 191",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/inner-flow/bienvenido-a-tu-primera-experiencia-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 192",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/inner-flow/spa-masaje-en-pareja/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 193",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/inner-flow/primera-vez-primer-masaje/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 194",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/inner-flow/primera-experiencia-en-pareja/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 195",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/inner-flow/spa-masaje/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 196",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/circuito-spa-adultos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 197",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-aromaterapia-25-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 198",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-aromaterapia-25-min-doble/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 199",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/ritual-kobido-emperatriz/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 200",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/ritual-flash/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 201",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-shi-zen-relax/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 202",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/circuito-spa-ninos-4-15-anos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 203",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-115-min-doble/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 204",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-140-min-doble/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 205",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-115-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 206",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/padres-e-hijos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 207",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/luz-del-mediterraneo/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 208",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-mediterranea/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 209",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-140-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 210",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-junior/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 211",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/relax-massage-30min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 212",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/relax-massage-50min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 213",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/love-massage-50min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 214",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 215",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/relax-massage-30min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 216",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/relax-massage-50min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 217",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/love-massage-50min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 218",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 219",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/pura-elegancia-by-alqvimia/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 220",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/terapia-lomi-lomi/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 221",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/masaje-personalizado/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 222",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/majestic-afterwork-masaje-coctel/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 223",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/masaje-facial-kobido/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 224",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/majestic-morning-up-desayuno-zona-de-hidroterapia-masaje/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 225",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/friendly-majestic-duo-moments/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 226",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/momento-quierete/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 227",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/zona-de-hidroterapia-facial-antiedad-cena/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 228",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/diamond-energy-by-natura-bisse/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 229",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/oriental-touch-signature-treatment/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 230",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/flash-facial/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 231",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/c-vitamin-coctel-by-natura-bisse/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 232",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/the-cure-by-natura-bisse/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 233",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/zona-de-hidroterapia/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 234",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/momento-romantico/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 235",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/pack-majestic-morning-up/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 236",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/toque-de-calma-by-alqvimia/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 237",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/termal-experience/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 238",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/experiencia-relax-termal-para-2/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 239",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-120-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 240",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-90-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 241",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-60-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 242",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/serenity-full-body-massage/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 243",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/sport-massage/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 244",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/detox-massage/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 245",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/deep-tissue-massage/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 246",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/digital-wellness-escape/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 247",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-y-gastro-mediodia-de-lunes-a-viernes-1/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 248",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/kobido-o-lifting-facial-japones/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 249",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/momentos-complices-adulto-nino-a-6-13-anos-1/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 250",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa-3/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 251",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-de-lunes-a-jueves-festivos-no-incluidos-2/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 252",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-y-gastro-cena-de-lunes-a-viernes-noches/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 253",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-120-min-viernes-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 254",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-y-gastro-cena-de-lunes-a-viernes/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 255",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/bano-de-hidromasaje-envoltura-de-algas-circuito-termal/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 256",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/luxury-private-spa-1/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 257",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/jacuzzi-masaje-en-pareja-circuito-termal/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 258",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/ritual-purificante-y-renovador-circuito-termal/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 259",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/facial-expres-con-acido-hialuronico-i-q10-circuito-termal/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 260",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/escapada-termal/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 261",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-gastro-fin-de-semana-y-festivos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 262",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-abyhanga/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 263",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-gastro-fines-de-semana-y-festivos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 264",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-relajante-en-pareja/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 265",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 266",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-viernes-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 267",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-de-lunes-a-jueves-festivos-no-incluidos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 268",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-y-gastro-almuerzo-de-lunes-a-viernes-mediodia/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 269",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-breakfast-lunes-a-viernes-festivos-no-incluidos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 270",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-breakfast-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 271",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/luxury-private-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 272",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa-2/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 273",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/drenaje-linfatico-de-50-circuito-termal/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 274",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/momentos-complices-adulto-nino-a-6-13-anos/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 275",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-subacuatico/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 276",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-siargao/relax-massage-30min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 277",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-siargao/relax-massage-50min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 278",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-siargao/love-massage-50min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 279",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-siargao/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 280",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/aquae-private-experience-para-2/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 281",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/romantic-private-experience-para-2/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 282",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/termal-private-experience-para-2/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 283",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/termal-experience/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 284",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/masaje-general/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 285",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/pack-relax-health-privee-circuito-privado-masaje-60-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 286",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/pack-relax-health-privee-circuito-privado-masaje-30-min/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 287",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-masaje-relax-30/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 288",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-deep-massage/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 289",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-masaje-relax-60/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 290",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 291",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/ritual-oxigen/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 292",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/ritual-go-sun-passion-fruit/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 293",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/masaje-relax-health-30/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 294",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/masaje-relax-health-60/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 295",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-privado/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 296",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/sesion-cromoterapia-masaje/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 297",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/deep-massage/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 298",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/sesion-reiki-masaje/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 299",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/rituales-diosas/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 300",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/date-un-capricho-acceso-al-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 301",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/express-massage-acceso-al-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 302",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/lulur-ritual-acceso-al-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 303",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/choccolat-massage-scrub-acceso-al-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 304",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/ritual-candle-acceso-al-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 305",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/magic-honey-acceso-al-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 306",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/acceso-al-spa-para-2/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.name ?? "Service landing: 307",
    path: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/back-soul-acceso-al-spa/",
    props: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.props ?? false,
    meta: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta || {},
    alias: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.alias || [],
    redirect: ServiceDetail_46pageq_45qWZdm3lNTGzpBrSFkuAi8KYYr3co_AvfhgDZ4sVa0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-01afd769-e493-415b-88d7-f8e85233b0ec/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]